import React, { useEffect, useState } from "react";

export default function MonthlyPage3({
  companyName,
  campaign,
  links,
  report,
  previewHtml,
  lastCampaign,
  lastReport,
  topLinks,
  previewRefPage3,
  pageNum,
}) {
  const [top, setTop] = useState(0);

  useEffect(() => {
    if (previewRefPage3.current) {
      previewRefPage3.current.innerHTML = previewHtml;
    }
  }, [previewRefPage3, previewHtml]);

  useEffect(() => {
    function findLocationOfTopClicked() {
      let topClicks = 0;
      let index = 0;
      let numberOfSameClicks = 0;
      links.forEach((element) => {
        if (element.url.includes(topLinks[0].url.split("?")[0])) {
          if (topClicks < element.totalClicks) {
            topClicks = element.totalClicks;
            index += numberOfSameClicks + 1;
            numberOfSameClicks = 0;
          } else if (topClicks === element.totalClicks) {
            numberOfSameClicks += 1;
          }
        }
      });

      const divDocument = previewRefPage3.current.ownerDocument;
      const linkElements = divDocument.querySelectorAll(
        `a[href^="${topLinks[0].url.split("?")[0]}"]`
      );

      let linkElement = null;
      if (pageNum === 3) {
        linkElement = linkElements[index];
      } else if (pageNum === 0) {
        if (index === 0) {
          linkElement = linkElements[index];
        } else {
        linkElement = linkElements[index - 1];
      }
    }

      return linkElement;
    }

    function scrollToCenterOfDesiredLink() {
      if (
        previewRefPage3 &&
        previewRefPage3.current &&
        topLinks &&
        topLinks[0] &&
        top === 0
      ) {
        const linkElement = findLocationOfTopClicked();
        let paddingTop = window.innerHeight * 0.5;

        setTop(linkElement.getBoundingClientRect().top - paddingTop);
      }
    }

    if (topLinks && previewRefPage3) {
      scrollToCenterOfDesiredLink();
    }
  }, [links, pageNum, previewRefPage3, top, topLinks]);

  function getNameOfLink(url) {
    return url.split("utm")[0];
  }

  function main() {
    if (campaign && topLinks && report && previewHtml)
      return (
        <>
          <div className="row content">
            <div className="col-md-6 left-side">
              <h1
                className="heading"
                style={{
                  fontSize: "5.4vh",
                }}
              >
                CLICKS REPORT
              </h1>
              <table className="data-table" style={{marginTop: "3vh"}}>
                <tbody>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(
                          campaign.reportSummary.subscriberClicks / 1000
                        ) > 0
                          ? Math.floor(
                              campaign.reportSummary.subscriberClicks / 1000
                            ) +
                            "," +
                            (campaign.reportSummary.subscriberClicks % 1000 <
                            100
                              ? (campaign.reportSummary.subscriberClicks %
                                  1000) %
                                  100 <
                                10
                                ? `00${
                                    campaign.reportSummary.subscriberClicks %
                                    1000
                                  }`
                                : `0${
                                    campaign.reportSummary.subscriberClicks %
                                    1000
                                  }`
                              : campaign.reportSummary.subscriberClicks % 1000)
                          : campaign.reportSummary.subscriberClicks}
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign
                          ? Math.floor(
                              lastCampaign.reportSummary.subscriberClicks / 1000
                            ) > 0
                            ? Math.floor(
                                lastCampaign.reportSummary.subscriberClicks /
                                  1000
                              ) +
                              "," +
                              (lastCampaign.reportSummary.subscriberClicks %
                                1000 <
                              100
                                ? (lastCampaign.reportSummary.subscriberClicks %
                                    1000) %
                                    100 <
                                  10
                                  ? `00${
                                      lastCampaign.reportSummary
                                        .subscriberClicks % 1000
                                    }`
                                  : `0${
                                      lastCampaign.reportSummary
                                        .subscriberClicks % 1000
                                    }`
                                : lastCampaign.reportSummary.subscriberClicks %
                                  1000)
                            : lastCampaign.reportSummary.subscriberClicks
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicked</h3>
                      <h5>
                        Number of people who clicked links within newsletter
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {Math.floor(campaign.reportSummary.clicks / 1000) > 0
                          ? Math.floor(campaign.reportSummary.clicks / 1000) +
                            "," +
                            (campaign.reportSummary.clicks % 1000 < 100
                              ? (campaign.reportSummary.clicks % 1000) % 100 <
                                10
                                ? `00${campaign.reportSummary.clicks % 1000}`
                                : `0${campaign.reportSummary.clicks % 1000}`
                              : campaign.reportSummary.clicks % 1000)
                          : campaign.reportSummary.clicks}
                      </h3>
                      <h3 className="secondary-number">
                        {lastCampaign
                          ? Math.floor(
                              lastCampaign.reportSummary.clicks / 1000
                            ) > 0
                            ? Math.floor(
                                lastCampaign.reportSummary.clicks / 1000
                              ) +
                              "," +
                              (lastCampaign.reportSummary.clicks % 1000 < 100
                                ? (lastCampaign.reportSummary.clicks % 1000) %
                                    100 <
                                  10
                                  ? `00${
                                      lastCampaign.reportSummary.clicks % 1000
                                    }`
                                  : `0${
                                      lastCampaign.reportSummary.clicks % 1000
                                    }`
                                : lastCampaign.reportSummary.clicks % 1000)
                            : lastCampaign.reportSummary.clicks
                          : 0}
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicks</h3>
                      <h5>
                        Total number of times links within newsletter were
                        clicked
                      </h5>
                    </td>
                  </tr>
                  <tr>
                    <td className="percentage-number">
                      <h3 className="primary-number">
                        {(
                          Math.round(
                            (report.clicks.uniqueSubscriberClicks /
                              report.opens.uniqueOpens) *
                              1000
                          ).toFixed(1) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                      <h3 className="secondary-number">
                        {lastReport
                          ? (
                              Math.round(
                                (lastReport.clicks.uniqueSubscriberClicks /
                                  lastReport.opens.uniqueOpens) *
                                  1000
                              ).toFixed(1) / 10
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })
                          : 0}
                        %
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicks per Unique Open</h3>
                      <h5>Percent of opened newsletters with clicks</h5>
                    </td>
                  </tr>
                  <tr>
                    <td className="percentage-number">
                      <h3 className="primary-number">
                        {(
                          Math.round(
                            report.clicks.clickRate *
                              1000
                          ).toFixed(1) / 10
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        })}
                        %
                      </h3>
                      <h3 className="secondary-number">
                        {lastReport
                          ? (
                              Math.round(
                                lastReport.clicks.clickRate *
                                  1000
                              ).toFixed(1) / 10
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 1,
                              maximumFractionDigits: 1,
                            })
                          : 0}
                        %
                      </h3>
                    </td>
                    <td className="data-name">
                      <h3>Clicks per Successful Delivery</h3>
                      <h5>Percent of clicked per recipients</h5>
                    </td>
                  </tr>
                  <tr className="blank-row">
                    <td colSpan="2"></td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ textAlign: "left" }}>
                      <h3 style={{ fontSize: "2.7vh" }}>TOP LINKS</h3>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {topLinks[0]
                          ? Math.floor(topLinks[0].clicks / 1000) > 0
                            ? Math.floor(topLinks[0].clicks / 1000) +
                              "," +
                              (topLinks[0].clicks % 1000 < 100
                                ? (topLinks[0].clicks % 1000) % 100 < 10
                                  ? `00${topLinks[0].clicks % 1000}`
                                  : `0${topLinks[0].clicks % 1000}`
                                : topLinks[0].clicks % 1000)
                            : topLinks[0].clicks
                          : 0}
                      </h3>
                      <h4>Clicks</h4>
                    </td>
                    <td
                      className="data-name"
                      style={{
                        display: "table-cell",
                        verticalAlign: "bottom",
                      }}
                    >
                      <p>
                        <a
                          href={topLinks[0] ? topLinks[0].url : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {topLinks[0] ? getNameOfLink(topLinks[0].url) : ""}
                        </a>
                      </p>
                      <h5> </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {topLinks[1]
                          ? Math.floor(topLinks[1].clicks / 1000) > 0
                            ? Math.floor(topLinks[1].clicks / 1000) +
                              "," +
                              (topLinks[1].clicks % 1000 < 100
                                ? (topLinks[1].clicks % 1000) % 100 < 10
                                  ? `00${topLinks[1].clicks % 1000}`
                                  : `0${topLinks[1].clicks % 1000}`
                                : topLinks[1].clicks % 1000)
                            : topLinks[1].clicks
                          : 0}
                      </h3>
                      <h4>Clicks</h4>
                    </td>
                    <td
                      className="data-name"
                      style={{
                        display: "table-cell",
                        verticalAlign: "bottom",
                      }}
                    >
                      <p>
                        <a
                          href={topLinks[1] ? topLinks[1].url : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {topLinks[1] ? getNameOfLink(topLinks[1].url) : ""}
                        </a>
                      </p>
                      <h5> </h5>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h3 className="primary-number">
                        {topLinks[2]
                          ? Math.floor(topLinks[2].clicks / 1000) > 0
                            ? Math.floor(topLinks[2].clicks / 1000) +
                              "," +
                              (topLinks[2].clicks % 1000 < 100
                                ? (topLinks[2].clicks % 1000) % 100 < 10
                                  ? `00${topLinks[2].clicks % 1000}`
                                  : `0${topLinks[2].clicks % 1000}`
                                : topLinks[2].clicks % 1000)
                            : topLinks[2].clicks
                          : 0}
                      </h3>
                      <h4>Clicks</h4>
                    </td>
                    <td
                      className="data-name"
                      style={{
                        display: "table-cell",
                        verticalAlign: "bottom",
                      }}
                    >
                      <p>
                        <a
                          href={topLinks[2] ? topLinks[2].url : ""}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {topLinks[2] ? getNameOfLink(topLinks[2].url) : ""}
                        </a>
                      </p>
                      <h5> </h5>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                className="legend"
                style={{ marginTop: companyName === "greenside" ? "70px" : "" }}
              >
                <div className="legend-item">
                  <span className="legend-symbol legend-symbol-1">●</span>
                  This Month
                </div>
                <div className="legend-item">
                  <span className="legend-symbol legend-symbol-2">●</span>
                  Previous Month
                </div>
              </div>
            </div>
            <div className="col-md-6 right-side">
              <div className="previewHtml">
                <img
                  src={"/images/phone.png"}
                  alt="Phone"
                  style={{
                    width: "19vw",
                    height: "100%",
                    position: "absolute",
                    top: "0",
                    right: "0",
                  }}
                />
                <div className="campaign-preview">
                  <div
                    ref={previewRefPage3}
                    style={{ marginTop: top > 0 ? -top : 0 }}
                  />
                </div>
              </div>
              <div className="circle"></div>
            </div>
          </div>
          <img src={"/images/HitSend-logo.png"} alt="Hit Send Logo" className="hitsend-logo" />
        </>
      );
    else return <>Loading...</>;
  }

  return <>{main()}</>;
}
